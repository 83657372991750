<template>
  <div class="page">
    <navTop title="详情信息" :leftIcon="true"></navTop>
    <div class="details" v-if="obj">
      <div class="home-bg">
        <div class="home-line">
          <div class="home-label">项目类型:</div>
          <div class="home-info">{{ obj.type_text? obj.type_text:'暂无' }}</div>
        </div>
        <div class="home-line">
          <div class="home-label">项目名称:</div>
          <div class="home-info">{{ obj.name? obj.name:'暂无' }}</div>
        </div>
        <!-- <div class="home-line">
          <div class="home-label">投资者:</div>
          <div class="home-info">{{ obj.investor? obj.investor:'暂无' }}</div>
        </div> -->
        <div class="home-line">
          <div class="home-label">投资总额:</div>
          <div class="home-info">{{ obj.input_money? obj.input_money:'暂无' }}</div>
        </div>
        <div class="home-line">
          <div class="home-label">县市:</div>
          <div class="home-info">{{ obj.address? obj.address:'暂无' }}</div>
        </div>
        <!-- <div class="home-line">
          <div class="home-label">挂点领导:</div>
          <div class="home-info">{{ obj.leader? obj.leader:'暂无' }}</div>
        </div>
        <div class="home-line">
          <div class="home-label">责任单位:</div>
          <div class="home-info">{{ obj.unit? obj.unit:'暂无' }}</div>
        </div> -->
        <div class="home-line">
          <div class="home-label">形象进度:</div>
          <div class="home-info">{{ obj.remark? obj.remark:'暂无' }}</div>
        </div>
      </div>
      <!-- <div class="home-bg">
        <div class="home-title">企业简介：</div>
        <div class="home-info-text1">
          {{ obj.company_introduce? obj.company_introduce:'暂无'}}
        </div>
      </div>
      <div class="home-bg">
        <div class="home-title">建设地址：</div>
        <div class="home-info-text">{{ obj.address? obj.address:'暂无' }}</div>
        <div class="home-title">项目简介：</div>
        <div ref="contentRef" class="project-content" v-html="obj.content"></div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { projectDetail } from "@/api/index";
import navTop from "@/components/common/navTop.vue";
export default {
  components: {
    navTop,
  },
  data() {
    return {
      obj: {},
    };
  },
  // updated(){
  //   const contentImages = this.$refs.contentRef.querySelectorAll("img");
  //     contentImages.forEach((img) => {
  //       img.style.maxWidth = "100%";
  //     });
  // },
  created() {
    let id = this.$route.query.id;
    this.getprojectDetail(id);
  },
  methods: {
    async getprojectDetail(id) {
      try {
        const { code, data } = await projectDetail({ project_id: id });
        this.obj = data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  mounted() {},
};
</script>

<style scoped>
.home-bg {
  background-color: #f3f3f3;
  border-radius: 15px;
  margin-bottom: 10px;
  padding: 15px 10px;
}
.home-line {
  display: flex;
  margin-bottom: 4px;
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.home-line:last-child{
  margin-bottom: 0px;
}
.home-label {
  color: #556efe;
  /* margin-right: 10rpx; */
  width: 75px;
}
.home-info {
  flex: 1;
  flex-wrap: wrap;
}
.home-title {
  color: #556efe;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 4px;
}
.home-info-text {
  font-size: 16px;
  color: #333;
  font-weight: 500;
  margin-bottom: 10px;
}
.home-info-text1 {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}
.details{
  padding: 0 15px;
  min-height: 100vh;
}

</style>
